import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../components/layout";
import Card from '../components/card';
import DOD from '../components/dods';
import HeroBanner from '../components/herobanner';
import useSiteMetadata from '../components/use-site-metadata';

export default function Template({
  data,
}) {
  
  const { siteUrl } = useSiteMetadata();
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  var bodyClasses = 'colleges ' + frontmatter.collegeAbrv;

  var alt = 'Photo of ' + frontmatter.title;

  var desc = html.replace(/(<([^>]+)>)/ig,"");
  desc = desc.split(".");

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <body className={bodyClasses} />
        <link rel="canonical" href={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta name="description" content={desc[0]} />

        {/* Schema.org markup for Google+ */}
        <meta itemprop="name" content={frontmatter.title} />
        <meta itemprop="description" content={desc[0]} />
        <meta itemprop="image" content={frontmatter.featuredImageHorizontal.childImageSharp.fixed.src} />

        {/* Twitter Card data */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={desc[0]} />
        <meta name="twitter:image:src" content={frontmatter.featuredImageHorizontal.childImageSharp.fixed.src} />

        {/* Open Graph data */}
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:url" content={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta property="og:image" content={frontmatter.featuredImageHorizontal.childImageSharp.fixed.src} />
        <meta property="og:image:alt" content={frontmatter.title} />
        <meta property="og:description" content={desc[0]} />
      </Helmet>
      {/* <div className="hero">
        <h1>{frontmatter.title}</h1>
        <img src={frontmatter.featuredImage.publicURL} alt="" />
      </div> */}
      <HeroBanner
        image={frontmatter.featuredImageHorizontal.childImageSharp.fluid.src}
        alt={alt}
        heading={frontmatter.title}
      />
      <section id="mainText">
        
        <article className="wrap">
          <div dangerouslySetInnerHTML={{ __html: html }} />
          {/* <hr /> */}
          <h2 class="center">Here's how you can be the difference</h2>
          <Card tag={frontmatter.collegeAbrv} />
        </article>
      </section>
      
      <DOD college={frontmatter.collegeAbrv} /> 
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        featuredImageHorizontal{
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 600, quality: 70, cropFocus: CENTER) {
              src
            }
            fixed(width: 1200, height: 630, fit: COVER, cropFocus: ATTENTION, quality: 50) {
              src
            }
          }
        }
        path
        title
        collegeAbrv
      }
    }
  }
`